// Dropdowns
.dropdown {
  .dropdown-menu {
    .dropdown-item {
      &:active {
        background-color: $grey-darken-1;
      }
    }
  }
}
