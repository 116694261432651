// Buttons
.btn {
  margin: $btn-margin-basic;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: $border-radius-base;
  box-shadow: $z-depth-1;
  transition: $btn-transition;
  @include button-size($btn-padding-y-basic, $btn-padding-x-basic, $btn-font-size-basic);

  @include hover-focus-active {
    outline: 0;
    box-shadow: $z-depth-1-half;
  }

  &.btn-block {
    margin: inherit;
  }

  .fas,
  .fab,
  .far {
    &.right {
      margin-left: $btn-icon-margin;
    }
    &.left {
      margin-right: $btn-icon-margin;
    }
  }

  &.btn-lg {
    @include button-size($btn-padding-y-large, $btn-padding-x-large, $btn-font-size-large);
  }
  &.btn-md {
    @include button-size($btn-padding-y-medium, $btn-padding-x-medium, $btn-font-size-medium);
  }
  &.btn-sm {
    @include button-size($btn-padding-y-small, $btn-padding-x-small, $btn-font-size-small);
  }

  &.disabled,
  &:disabled {
    @include hover-focus-active {
      box-shadow: $z-depth-1;
    }
  }

  &[class*="btn-outline-"] {
    padding-top: $btn-outline-padding-y-basic;
    padding-bottom: $btn-outline-padding-y-basic;
    &.btn-lg {
      padding-top: $btn-outline-padding-y-large;
      padding-bottom: $btn-outline-padding-y-large;
    }
    &.btn-md {
      padding-top: $btn-outline-padding-y-medium;
      padding-bottom: $btn-outline-padding-y-medium;
    }
    &.btn-sm {
      padding-top: $btn-outline-padding-y-small;
      padding-bottom: $btn-outline-padding-y-small;
    }
  }
}

.btn-link {
  color: $black-base;
  background-color: transparent;
  box-shadow: none;
  @include hover-focus-active {
    color: $black-base;
    background-color: transparent;
    box-shadow: none;
  }
}

.btn-group {
  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: -$btn-group-margin;
  }
}

@each $btn_name, $color_value in $mdb-colors {
  @include make-button($btn_name, $color_value);
  @include make-outline-button($btn_name, $color_value);
}

@each $name, $val in $gradients {
  @include make-gradient-button($name, $val);
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: $white-base;
}

